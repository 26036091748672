import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    public static serviceCount = 0;
    public static showLoader = false;
    public addOne = 1;
    public lessOne = -1;

    constructor(private communicationService: CommunicationService) {}

    public showLoader() {
        this.handleLoader(this.addOne);
    }

    public hideLoader() {
        this.handleLoader(this.lessOne);
    }

    public handleLoader(count: number) {
        LoaderService.serviceCount = LoaderService.serviceCount + count;
        if (LoaderService.serviceCount > 0) {
            LoaderService.showLoader = true;
            setTimeout(() => {
                this.communicationService.broadcast('show', LoaderService.showLoader);
            }, 10);
        } else {
            LoaderService.showLoader = false;
            setTimeout(() => {
                this.communicationService.broadcast('show', LoaderService.showLoader);
            }, 10);
        }
    }
}

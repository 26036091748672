<div class="container">
    <div class="title">
        {{'unverified-user.title' | translate}}
    </div>
    <div class="subtitle">
        {{'unverified-user.subtitle' | translate}}
    </div>
    <button class="button blue mt-48" type="button" [routerLink]="['/login']" [queryParams]="{requestId: requestId}">
        {{"unverified-user.login" | translate}}
    </button>
</div>


import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';

export class MobileNumberValidator {
    static validateMobile(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value.startsWith('+')) {
                if (isValidNumber(control.value)) {
                    return null;
                } else {
                    return { invalidMobile: true };
                }
            } else {
                return { invalidMobile: true };
            }
        };
    }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reset-success',
    templateUrl: './reset-success.component.html',
    styleUrls: ['./reset-success.component.scss'],
})
export class ResetSuccessComponent implements OnInit {
    requestId: string;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        window.document.title = 'Passwort zurücksetzen';
    }

    ngOnInit() {
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    }
}

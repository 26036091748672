import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordPolicyValidation {
    static requireDigits(digitCount: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const password = control.value; // to get value in input tag
            const count = (password.match(/\d/g) || []).length;
            if (Number(count) < digitCount) {
                return { minDigitsRequired: true };
            } else {
                return null;
            }
        };
    }

    static requireSpecialChars(specialCharsCount: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const password = control.value; // to get value in input tag
            const count = (password.match(/\W/g) || []).length;
            if (Number(count) < specialCharsCount) {
                return { specialCharsRequired: true };
            } else {
                return null;
            }
        };
    }

    static requireLowerandUpper(): ValidatorFn {
        const lowerCasePattern = /([a-z]){1,}/;
        const upperCasePattern = /([A-Z]){1,}/;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.value.match(lowerCasePattern) || !control.value.match(upperCasePattern)) {
                return { lowerAndUpperRequired: true };
            } else {
                return null;
            }
        };
    }
}

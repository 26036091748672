import { Component, OnInit } from '@angular/core';
import { CidaasService, LoaderService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-scope-consent',
    templateUrl: './scope-consent.component.html',
    styleUrls: ['./scope-consent.component.scss'],
})
export class ScopeConsentComponent implements OnInit {
    requestId: string;
    trackId: string;
    categoryArray: any[];
    language: string;
    localnavigator: any = window.navigator as any;
    sub = '';
    clientId = '';

    constructor(
        private route: ActivatedRoute,
        private cidaasService: CidaasService,
        private router: Router,
        private loader: LoaderService
    ) {}

    ngOnInit() {
        this.requestId = this.route.snapshot.queryParams.requestId;
        this.trackId = this.route.snapshot.queryParams.trackId;
        this.sub = this.route.snapshot.queryParams.sub;
        this.clientId = this.route.snapshot.queryParams.client_id;
        this.language = this.localnavigator.language;
        if (!this.language) {
            this.language = this.localnavigator.systemLanguage;
        }
        if (!this.language) {
            this.language = 'de';
        }
        this.getScopeConsentDetails();
    }

    getScopeConsentDetails() {
        this.loader.showLoader();
        const options = {
            trackId: this.trackId,
            locale: this.language,
        };
        this.cidaasService
            .getScopeConsentDetails(options)
            .then((response: any) => {
                if (
                    response &&
                    response.data &&
                    response.data.meta_data &&
                    response.data.meta_data.scopes &&
                    response.data.meta_data.scopes.length > 0
                ) {
                    this.categoryArray = response.data.meta_data.scopes;
                }
                this.loader.hideLoader();
            })
            .catch(() => {
                this.loader.hideLoader();
            });
    }

    acceptScopeConsent() {
        this.loader.showLoader();
        const scopes: string[] = [];
        if (this.categoryArray && this.categoryArray.length > 0) {
            this.categoryArray.forEach((scope) => {
                scopes.push(scope.scope);
            });
        }
        this.cidaasService
            .acceptScopeConsent({
                scopes,
                sub: this.sub,
                clientId: this.clientId,
            })
            .then(() => {
                this.loader.hideLoader();
                this.consentContinue();
            })
            .catch(() => {
                this.loader.hideLoader();
            });
    }

    consentContinue() {
        this.loader.showLoader();
        this.cidaasService.scopeConsentContinue({
            trackId: this.trackId,
        });
    }
}

<div class="column-container">
    <div class="container">
        <div class="title">{{ 'register.title' | translate }}</div>
        <div class="subtitle">
            {{ 'register.subtitle.text' | translate }}
            <a [routerLink]="['/login-credentials']" [queryParams]="{ requestId: requestId }">{{ 'register.subtitle.link' | translate }}</a>
        </div>

        <div class="buttons social">
            <button (click)="socialSignup('google')" class="button" mat-flat-button>Registrieren mit Google</button>
            <button (click)="socialSignup('apple')" class="button" mat-flat-button>Registrieren mit Apple</button>
        </div>

        <div class="divider">
            <span>oder</span>
        </div>

        <form class="form" [formGroup]="registrationForm" *ngIf="registrationFields && registrationFields.length > 0">
            <div *ngFor="let field of registrationFields" class="form-field-container">
                <mat-form-field *ngIf="field.key === 'given_name'" color="accent" appearance="outline" class="form-field small first-name">
                    <mat-label>{{ 'register.name' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                        required
                    />
                    <mat-error *ngIf="registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid">
                        {{ 'register.required-vorname' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid && submitted"
                    >
                        {{ 'register.required-vorname' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="field.key === 'family_name'" color="accent" appearance="outline" class="form-field small">
                    <mat-label>{{ 'register.family-name' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                        required
                    />
                    <mat-error *ngIf="registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid">
                        {{ 'register.required-nachname' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid && submitted"
                    >
                        {{ 'register.required-nachname' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required-family-name' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field
                    *ngIf="field.key !== 'family_name' && field.key !== 'given_name' && field.inputType === 'TEXT'"
                    color="accent"
                    appearance="outline"
                    class="form-field large"
                >
                    <mat-label>
                        {{ field.name }}
                    </mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                        required
                    />
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="field.inputType === 'EMAIL'" color="accent" appearance="outline" class="form-field large">
                    <mat-label>{{ 'register.email' | translate }}</mat-label>
                    <input
                        matInput
                        type="email"
                        autocomplete="username"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [class.input-error]="
                            (registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.email) ||
                            (!registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required)
                        "
                        [id]="field.key"
                        class="input-field"
                        required
                    />
                    <mat-error *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.email">
                        {{ 'register.invalid-email' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required-email' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.isDuplicateEmail"
                    >
                        {{ 'register.duplicateEmail' | translate }}
                    </mat-error>
                </mat-form-field>

                <ng-container *ngIf="field.inputType === 'MOBILE'">
                    <mat-form-field color="accent" appearance="outline" class="mobile-code">
                        <mat-label>{{ 'register.phone-country' | translate }}</mat-label>
                        <mat-select
                            [(ngModel)]="phoneCodeInputValue"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="phoneNumberChange(field.key)"
                            (selectionChange)="onPhoneNumberChange(field.key)"
                        >
                            <mat-option
                                *ngFor="let code of phoneNumberUtil.getSupportedRegions()"
                                [value]="'+' + phoneNumberUtil.getCountryCodeForRegion(code)"
                            >
                                {{ code }} +{{ phoneNumberUtil.getCountryCodeForRegion(code) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" appearance="outline" class="mobile-number">
                        <mat-label>
                            {{ (field.isRequired ? 'register.phone-number' : 'register.phone-number-optional') | translate }}
                        </mat-label>
                        <input
                            matInput
                            [errorStateMatcher]="matcher"
                            type="text"
                            [(ngModel)]="phoneNumberInputValue"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="phoneNumberChange(field.key)"
                            [class.input-error]="
                                !registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required
                            "
                            class="input-field"
                            (change)="onPhoneNumberChange(field.key)"
                            [required]="field.isRequired"
                        />
                        <mat-error
                            *ngIf="
                                !registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required
                            "
                        >
                            {{ 'register.required' | translate }}
                        </mat-error>
                        <mat-error
                            class="error"
                            *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.invalidMobile"
                        >
                            {{ 'register.incorrect-mobile' | translate }}
                        </mat-error>
                        <mat-error class="error" *ngIf="registrationForm.controls[field.key]?.hasError('errorStartingZero')">
                            {{ 'register.error-starting-zero' | translate }}
                        </mat-error>
                        <mat-error class="error" *ngIf="registrationForm.controls[field.key]?.hasError('errorPhoneValidation')">
                            {{ 'register.incorrect-mobile' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <input type="hidden" [formControlName]="field.key" [name]="field.key" [id]="field.key" />
                </ng-container>

                <mat-form-field
                    *ngIf="field.inputType === 'PASSWORD'"
                    color="accent"
                    appearance="outline"
                    class="form-field password-field large"
                >
                    <mat-label>
                        <ng-container class="actual_pass" *ngIf="field.key === 'password'">
                            {{ 'register.password' | translate }}
                        </ng-container>
                        <ng-container *ngIf="field.key === 'password_echo'">
                            {{ 'register.password-repeat' | translate }}
                        </ng-container>
                    </mat-label>
                    <input
                        matInput
                        type="password"
                        autocomplete="new-password"
                        (keyup)="validatePassword()"
                        (keydown)="validatePassword()"
                        (blur)="validatePassword()"
                        [formControlName]="field.key"
                        [name]="'notsearch_password'"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                        required
                    />
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        <ng-container *ngIf="field.key === 'password'">
                            {{ 'register.required-password' | translate }}
                        </ng-container>
                        <ng-container *ngIf="field.key === 'password_echo'">
                            {{ 'register.required-password-repeat' | translate }}
                        </ng-container>
                    </mat-error>

                    <!--                    <mat-error-->
                    <!--                        *ngIf="-->
                    <!--                            registrationForm.controls[field.key].value &&-->
                    <!--                            (registrationForm.controls[field.key].errors?.lowerAndUpperRequired ||-->
                    <!--                                registrationForm.controls[field.key].errors?.MinDigitsRequired ||-->
                    <!--                                registrationForm.controls[field.key].errors?.SpecialCharsRequired ||-->
                    <!--                                registrationForm.controls[field.key].errors?.minlength ||-->
                    <!--                                registrationForm.controls[field.key].errors?.maxlength)-->
                    <!--                        "-->
                    <!--                    >-->
                    <!--                        {{ 'register.password-error-message' | translate }}-->
                    <!--                    </mat-error>-->

                    <mat-error
                        *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.matchPassword"
                    >
                        {{ 'register.not-match-password' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-hint *ngIf="this.passwordHints.length && field.key === 'password'" class="password-hints">
                    <div *ngFor="let error of this.passwordHints">{{ 'password-validator.' + error.error | translate : error }}</div>
                </mat-hint>

                <div *ngIf="field.inputType === 'CHECKBOX'">
                    <div formArrayName="{{ field.key }}" *ngFor="let chk_box of registrationForm.get(field.key).controls; let i = index">
                        <label class="input-element">
                            <mat-checkbox [formControlName]="i" [required]="field.validations.required && !field.value">
                                {{ field.options[i].value }}
                            </mat-checkbox>
                        </label>
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ field.validations?.required?.message }}
                    </div>
                </div>

                <div *ngIf="field.key === 'availability_euerzuhause'" class="form-field radio">
                    <div>{{ field.label }}</div>
                    <mat-radio-group
                        class="availability"
                        formControlName="{{ field.key }}"
                        [required]="field.validations.required && !field.value"
                    >
                        <mat-radio-button
                            class="radio-label"
                            *ngFor="let option of field.options; let last = last"
                            [value]="option.value"
                            [checked]="last"
                            >{{ option.key }}</mat-radio-button
                        >
                    </mat-radio-group>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ field.validations?.required?.message }}
                    </div>
                </div>

                <div *ngIf="field.inputType === 'CONSENT'">
                    <!--div class="gdpr-note">
                        <img class="gdpr-image" src="../../../assets/img/dsgvo-logo.png" />
                        <div class="gdpr-text">{{ 'register.security-note' | translate }}</div>
                    </div-->
                    <label class="input-element">
                        <mat-checkbox formControlName="{{ field.key }}" [required]="field.validations.required && !field.value">
                            <span [innerHTML]="field.collections.label?.replaceAll('<a', '<a target=_blank') | safeHtml"></span>
                        </mat-checkbox>
                    </label>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.accept-terms-required' | translate }}
                    </div>
                </div>
            </div>
            <button [disabled]="!registrationForm.valid" mat-button mat-flat-button color="primary" type="submit" (click)="register()">
                {{ 'register.create-account' | translate }}
            </button>
            <div *ngIf="isRegisterError && registrationForm.valid" class="error center mt-16">
                {{ this.registerError | translate }}
            </div>
        </form>
    </div>
</div>

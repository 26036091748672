<div class="container">
    <div class="title">
        {{ 'reset-password.title' | translate }}
    </div>
    <div class="subtitle">
        {{ 'reset-password.subtitle' | translate }}
    </div>
    <form class="form" [formGroup]="resetPasswordForm">
        <mat-form-field color="accent" appearance="outline" class="form-field">
            <mat-label>
                {{ 'reset-password.new-password' | translate }}
            </mat-label>
            <input matInput type="password" formControlName="password" name="password" id="password" class="input-field" />
            <mat-error
                class="error"
                *ngIf="!resetPasswordForm.controls.password.valid && resetPasswordForm.controls.password.errors?.required && submitted"
            >
                {{ 'reset-password.required-password' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="
                    resetPasswordForm.controls.password.value &&
                    !resetPasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !resetPasswordForm.controls.password.errors?.MinDigitsRequired &&
                    !resetPasswordForm.controls.password.errors?.SpecialCharsRequired &&
                    resetPasswordForm.controls.password.errors?.minlength
                "
            >
                {{ 'reset-password.min-length-password' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="
                    resetPasswordForm.controls.password.value &&
                    !resetPasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !resetPasswordForm.controls.password.errors?.MinDigitsRequired &&
                    !resetPasswordForm.controls.password.errors?.SpecialCharsRequired &&
                    resetPasswordForm.controls.password.errors?.maxlength
                "
            >
                {{ 'reset-password.max-length-password' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="resetPasswordForm.controls.password.value && resetPasswordForm.controls.password.errors?.lowerAndUpperRequired"
            >
                {{ 'reset-password.lower-upper-case-password' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="
                    resetPasswordForm.controls.password.value &&
                    !resetPasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    resetPasswordForm.controls.password.errors?.MinDigitsRequired
                "
            >
                {{ 'reset-password.digits-required-password' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="
                    resetPasswordForm.controls.password.value &&
                    !resetPasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !resetPasswordForm.controls.password.errors?.MinDigitsRequired &&
                    resetPasswordForm.controls.password.errors?.SpecialCharsRequired
                "
            >
                {{ 'reset-password.special-chars-required-password' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="outline" class="form-field">
            <mat-label>
                {{ 'reset-password.repeat-password' | translate }}
            </mat-label>
            <input matInput type="password" formControlName="passwordEcho" name="passwordEcho" id="passwordEcho" class="input-field" />
            <mat-error
                class="error"
                *ngIf="
                    !resetPasswordForm.controls.passwordEcho.valid && resetPasswordForm.controls.passwordEcho.errors?.required && submitted
                "
            >
                {{ 'reset-password.required-password-repeat' | translate }}
            </mat-error>
            <mat-error
                class="error"
                *ngIf="resetPasswordForm.controls.passwordEcho.value && resetPasswordForm.controls.passwordEcho.errors?.matchPassword"
            >
                {{ 'reset-password.not-match-password' | translate }}
            </mat-error>
        </mat-form-field>

        <button
            mat-button
            mat-flat-button
            color="primary"
            class="button blue mt-48"
            type="submit"
            (click)="changePassword(resetPasswordForm, resetPasswordForm.valid)"
        >
            {{ 'reset-password.reset' | translate }}
        </button>
    </form>
</div>

export const getCookie = (name: string): string | undefined => {
    const cookies: Record<string, string> = {};
    document.cookie.split(';').forEach((cookie) => {
        const [key, value] = cookie.split('=');
        Object.assign(cookies, { [key.trim()]: decodeURIComponent(value) });
    });
    return cookies[name];
};

export const setCookie = ({
    name,
    value,
    domain,
    expirationInMs,
    path = '/',
}: {
    name: string;
    value: string;
    domain: string;
    expirationInMs: number;
    path?: string;
}) => {
    const date = new Date();
    date.setTime(date.getTime() + expirationInMs);
    document.cookie = `${name}=${encodeURIComponent(value)}; domain=${domain}; expires=${date.toUTCString()}; path=${path}`;
};

export const deleteCookie = ({ name, domain, path = '/' }: { name: string; domain: string; path?: string }) =>
    setCookie({ name, value: '1', domain, expirationInMs: -100, path });

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export type SocialProvider = 'google' | 'apple';

@Injectable({
    providedIn: 'root',
})
export class SocialLoginService {
    constructor() {}

    login(provider: SocialProvider, requestId: string) {
        window.location.href = environment.loginBaseURL + '/login-srv/social/login/' + provider + '/' + requestId;
    }
}

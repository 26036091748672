export class StorageHelper {
    // Save Data to Localstorage
    static saveData(key: string, value: string) {
        if (key && value) {
            if (localStorage) {
                localStorage.setItem(key, value);
            } else if (sessionStorage) {
                sessionStorage.setItem(key, value);
            } else {
                console.log('upgrade your browser or enable localstorage');
            }
        } else {
            console.log('key or value is undefined!');
        }
    }

    static openNav() {
        document.getElementById('mySidenav').style.width = '380px';
        document.getElementById('mySidenav').style.marginLeft = '-15px';
    }

    // Get Data from Localstorage
    static getData(key: string): string {
        if (localStorage) {
            return localStorage.getItem(key);
        } else if (sessionStorage) {
            return sessionStorage.getItem(key);
        } else {
            return null;
        }
    }

    // Delete Data from Localstorage
    static deleteData(key: string) {
        if (localStorage) {
            localStorage.removeItem(key);
        } else if (sessionStorage) {
            return sessionStorage.removeItem(key);
        }
    }

    // Delete All Data from Localstorage
    static deleteAll() {
        if (localStorage) {
            localStorage.clear();
        } else if (sessionStorage) {
            return sessionStorage.clear();
        }
    }
}

import { Injectable } from '@angular/core';
import * as data from '../../assets/lang/de.json';
import * as dataen from '../../assets/lang/en.json';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    // variables
    language: string;
    localnavigator: any = window.navigator as any;
    enString: any = dataen['default'];
    deString: any = data['default'];

    constructor() {
        this.language = this.localnavigator.language;
        if (!this.language) {
            this.language = this.localnavigator.systemLanguage;
        }
        if (!this.language) {
            this.language = 'en';
        }
    }

    getTranslatedMessage(message) {
        let dataToReturn: any;
        if (this.language.indexOf('de') >= 0) {
            dataToReturn = get(this.deString, message);
        } else if (this.language.indexOf('en') >= 0) {
            dataToReturn = get(this.enString, message);
        } else {
            dataToReturn = get(this.deString, message);
        }

        if (dataToReturn == null) {
            return message;
        }
        if (typeof dataToReturn === 'string') {
            dataToReturn = ' ' + dataToReturn + ' ';
        }
        return dataToReturn;
    }
}

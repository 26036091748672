import { StorageHelper } from './storage-helper';

export class LocaleHelper {
    // variables
    static language: string;
    static localnavigator: any = window.navigator as any;

    // Get Browser Language
    static getBrowserLanguage(): string {
        this.language = 'de';
        return this.language;
        // const locale = StorageHelper.getData('uiLocales');
        // if (locale && locale !== '') {
        //     const list: string[] = locale.split(',');
        //     if (list && list.length > 0) {
        //         this.language = list[0];
        //     } else {
        //         this.language = 'de';
        //     }
        // } else {
        //     this.language = this.localnavigator.language;
        //     if (!this.language) {
        //         this.language = this.localnavigator.systemLanguage;
        //     }
        //     if (!this.language) {
        //         this.language = 'de';
        //     }
        // }
        // return this.language;
    }
}

<div class="container">
    <div class="title">
        {{ 'mfa-verify.title' | translate }}
    </div>
    <div class="subtitle" *ngIf="type === 'totp'">
        {{ 'mfa-verify.subtitle' | translate }}
    </div>
    <div class="subtitle" *ngIf="type === 'email'">
        {{ 'mfa-verify.subtitle-email' | translate }}
    </div>
    <form [formGroup]="mfaVerifyForm">
        <div>
            <label class="input-label">
                {{ 'mfa-verify.enter-code' | translate }}
                <input type="password" formControlName="code" name="code" id="code" class="input-field" />
            </label>
        </div>
        <div class="error" *ngIf="!mfaVerifyForm.controls.code.valid && submitted">
            {{ 'mfa-verify.invalid-code' | translate }}
        </div>

        <div *ngIf="type === 'totp'">
            <div>
                <label
                    class="alternative-options"
                    [routerLink]="['/mfa']"
                    [queryParams]="{ sub: sub, trackId: trackId, requestId: requestId, alternative: true }"
                >
                    <span class="alternative-span"
                        >{{ 'mfa-verify.device-not-available' | translate }}
                        <a class="alternative_options">{{ 'mfa-verify.alternative-options' | translate }}</a>
                    </span>
                </label>
            </div>
        </div>

        <button class="button blue mt-48" type="submit" (click)="onVerify(mfaVerifyForm.value, mfaVerifyForm.valid)">
            {{ 'mfa-verify.submit' | translate }}
        </button>
        <button class="button mt-16" [routerLink]="['/login']" [queryParams]="{ requestId: requestId }">
            {{ 'mfa-verify.back' | translate }}
        </button>

        <div class="error mt-16" *ngIf="isVerifyError">
            <span>{{ 'mfa-verify.invalid-code-error' | translate }}</span>
        </div>
    </form>
</div>

import { Routes } from '@angular/router';
import { RegisterComponent } from '../views/register/register.component';
import { MfaComponent } from '../views/mfa/mfa.component';
import { MfaVerifyComponent } from '../views/mfa-verify/mfa-verify.component';
import { ExtraComponent } from '../views/extra/extra.component';
import { ForgetPasswordComponent } from '../views/forget-password/forget-password.component';
import { ForgetSuccessComponent } from '../views/forget-success/forget-success.component';
import { ResetPasswordComponent } from '../views/reset-password/reset-password.component';
import { ChangePasswordComponent } from '../views/change-password/change-password.component';
import { ConsentComponent } from '../views/consent/consent.component';
import { ScopeConsentComponent } from '../views/scope-consent/scope-consent.component';
import { RegisterSuccessComponent } from '../views/register-success/register-success.component';
import { VerificationComponent } from '../views/verification/verification.component';
import { ResetSuccessComponent } from '../views/reset-success/reset-success.component';
import { UnverifiedUserComponent } from '../views/unverified-user/unverified-user.component';
import { LoginCredentialsComponent } from '../views/login-credentials/login-credentials.component';

export const routes: Routes = [
    { path: 'login', redirectTo: 'register', pathMatch: 'full' },
    { path: 'login-credentials', component: LoginCredentialsComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'extra', component: ExtraComponent },
    { path: 'reset/:requestId', component: ForgetPasswordComponent },
    { path: 'reset_success', component: ForgetSuccessComponent },
    { path: 'unverified_user', component: UnverifiedUserComponent },
    { path: 'forgot_success', component: ResetSuccessComponent },
    { path: 'reset', component: ResetPasswordComponent },
    { path: 'changepassword', component: ChangePasswordComponent },
    { path: 'mfa', component: MfaComponent },
    { path: 'consent', component: ConsentComponent },
    { path: 'scope_consent', component: ScopeConsentComponent },
    { path: 'reg_success', component: RegisterSuccessComponent },
    { path: 'verification', component: VerificationComponent },
    { path: 'mfaverify/:statusId/:type/:sub/:trackId/:requestId', component: MfaVerifyComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

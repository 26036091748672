<div class="container">
    <img src="../../../assets/img/checkmark.svg" alt="Email Confirmed" class="image" />

    <div class="content">{{ 'register.success.content' | translate }}</div>

    <div class="return-to-login">
        <button
            class="button"
            mat-flat-button
            color="accent"
            [queryParams]="{ requestId: requestId }"
            [routerLink]="['/login-credentials']"
        >
            Zurück zum Login
        </button>
    </div>
</div>

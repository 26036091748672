<div class="container">
    <div class="title">
        {{ 'scope-consent.title' | translate }}
    </div>
    <ul class="scope_consent_ul">
        <li *ngFor="let cat of categoryArray" class="scope_consent_li">
            <label class="scope_consent_label">{{ cat.scope }}:</label>
            <span class="scope_consent_span">{{ cat.description }}</span>
        </li>
    </ul>
    <button class="button blue mt-48" (click)="acceptScopeConsent()">
        {{ 'scope-consent.accept' | translate }}
    </button>
    <button class="button mt-16" [routerLink]="['/login']" [queryParams]="{ requestId: requestId }">
        {{ 'scope-consent.cancel' | translate }}
    </button>
</div>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-unverified-user',
    templateUrl: './unverified-user.component.html',
    styleUrls: ['./unverified-user.component.scss']
})
export class UnverifiedUserComponent implements OnInit {

    requestId: string;

    constructor(private activatedRoute: ActivatedRoute) {
        window.document.title = 'Register Success';
    }

    ngOnInit() {
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './config/routes';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LoaderComponent } from './views/loader/loader.component';
import { MfaComponent } from './views/mfa/mfa.component';
import { MfaVerifyComponent } from './views/mfa-verify/mfa-verify.component';
import { ExtraComponent } from './views/extra/extra.component';
import { ForgetPasswordComponent } from './views/forget-password/forget-password.component';
import { ForgetSuccessComponent } from './views/forget-success/forget-success.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ConsentComponent } from './views/consent/consent.component';
import { ScopeConsentComponent } from './views/scope-consent/scope-consent.component';
import { RegisterSuccessComponent } from './views/register-success/register-success.component';
import { VerificationComponent } from './views/verification/verification.component';
import { ResetSuccessComponent } from './views/reset-success/reset-success.component';
import { UnverifiedUserComponent } from './views/unverified-user/unverified-user.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { LoginCredentialsComponent } from './views/login-credentials/login-credentials.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';

export const createTranslateLoader = (http: HttpClient) => {
    const currentDate = new Date();
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    const version = today.getTime();
    return new TranslateHttpLoader(http, './assets/lang/', '.json?v=' + version + '_001');
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoginCredentialsComponent,
        RegisterComponent,
        LoaderComponent,
        MfaComponent,
        MfaVerifyComponent,
        ExtraComponent,
        ForgetPasswordComponent,
        ForgetSuccessComponent,
        ResetPasswordComponent,
        ChangePasswordComponent,
        ConsentComponent,
        ScopeConsentComponent,
        RegisterSuccessComponent,
        VerificationComponent,
        ResetSuccessComponent,
        UnverifiedUserComponent,
        SafeHtmlPipe,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {}),
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        SharedComponentsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic',
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    loginBaseURL: 'https://rehau-free.cidaas.de',
    mixpanelToken: '58a436675a65b3aa685b26eda825c6bd',
    clientId: '9f26aac1-d632-49df-a2d3-9be74f2b4ef3',
    // clientId: 'a4e81e51-66a4-4b41-b3cb-fc184d074040', // euerzuhause-localhost app
    redirectUrl: '/dashboard', // Currently not used
    authRequestRedirect: 'http://localhost:4200', // When used as entrypoint
    googleAnalytics: 'G-EWPP4EPEEK',
    phoneVerification: 'https://api.numlookupapi.com',
    phoneVerificationApiKey: 'sWoak2n5lZDkmW4c1gFj9UcZP1hRunf9gzoj6Kim',
    landingPage: 'https://www.euerzuhause.de',
    termsOfServicePage: 'https://www.euerzuhause.de/agbs',
    dataPrivacyPage: 'https://www.euerzuhause.de/datenschutz',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<div class="container">
    <div class="title">Unsere Nutzungsbedingungen haben sich geändert</div>
    <div class="subtitle">Um fortzufahren musst du die Teilnahmebedingungen akzeptieren.</div>
    <div>
        <ul class="scope_consent_ul">
            <li *ngFor="let scopes of consentScopes" class="scope_consent_li">
                <label class="scope_consent_label">{{ scopes.scopeKey }}:</label>
                <span class="scope_consent_span">{{ scopes.description }}</span>
            </li>
        </ul>
        <div class="consent-content">
            <a [href]="consentResponse?.url" target="_blank">{{ consentResponse?.content }}</a>
        </div>
    </div>
    <div class="buttons">
        <button mat-flat-button color="primary" (click)="acceptConsent()">
            {{ 'consent.accept' | translate }}
        </button>
        <button mat-flat-button color="accent" [routerLink]="['/login']" [queryParams]="{ requestId: requestId }">
            {{ 'consent.cancel' | translate }}
        </button>
    </div>
</div>

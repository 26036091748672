import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SystemMsgComponent } from './system-msg/system-msg.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations: [HeaderComponent, SystemMsgComponent, FooterComponent],
    exports: [HeaderComponent, FooterComponent, SystemMsgComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatListModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatMenuModule,
        MatCardModule,
    ],
})
export class SharedComponentsModule {}

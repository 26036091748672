import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor() {}
}

export const registerGAEvent = (event: string, pageName: string, bodyParams?: { [key: string]: any }) => {
    if (window?.hasOwnProperty('gtag') && typeof (window as any).gtag === 'function') {
        (window as any)?.gtag(event, pageName, bodyParams);
    }
};

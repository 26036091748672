import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-forget-success',
    templateUrl: './forget-success.component.html',
    styleUrls: ['./forget-success.component.scss'],
})
export class ForgetSuccessComponent implements OnInit {
    requestId: string;
    email: string;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        window.document.title = 'Passwort vergessen';
        this.email = router.getCurrentNavigation()?.extras?.state['email'] || '';
    }

    ngOnInit() {
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    }
}

<div class="container">
    <div class="title">
        {{ 'change-password.title' | translate }}
    </div>

    <form class="form" [formGroup]="changePasswordForm">
        <div>
            <label class="input-label">
                {{ 'change-password.old-password' | translate }}
                <input type="password" formControlName="oldPassword" name="oldPassword" id="oldPassword" class="input-field" />
            </label>
            <div
                class="error"
                *ngIf="
                    !changePasswordForm.controls.oldPassword.valid && changePasswordForm.controls.oldPassword.errors?.required && submitted
                "
            >
                {{ 'change-password.required-old-password' | translate }}
            </div>
        </div>

        <div>
            <label class="input-label">
                {{ 'change-password.new-password' | translate }}
                <input type="password" formControlName="password" name="password" id="password" class="input-field" />
            </label>
            <div
                class="error"
                *ngIf="!changePasswordForm.controls.password.valid && changePasswordForm.controls.password.errors?.required && submitted"
            >
                {{ 'change-password.required-new-password' | translate }}
            </div>
            <div
                class="error"
                *ngIf="
                    changePasswordForm.controls.password.value &&
                    !changePasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !changePasswordForm.controls.password.errors?.MinDigitsRequired &&
                    !changePasswordForm.controls.password.errors?.SpecialCharsRequired &&
                    changePasswordForm.controls.password.errors?.minlength
                "
            >
                {{ 'change-password.min-length-password' | translate }}
            </div>
            <div
                class="error"
                *ngIf="
                    changePasswordForm.controls.password.value &&
                    !changePasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !changePasswordForm.controls.password.errors?.MinDigitsRequired &&
                    !changePasswordForm.controls.password.errors?.SpecialCharsRequired &&
                    changePasswordForm.controls.password.errors?.maxlength
                "
            >
                {{ 'change-password.max-length-password' | translate }}
            </div>
            <div
                class="error"
                *ngIf="changePasswordForm.controls.password.value && changePasswordForm.controls.password.errors?.lowerAndUpperRequired"
            >
                {{ 'change-password.lower-upper-case-password' | translate }}
            </div>
            <div
                class="error"
                *ngIf="
                    changePasswordForm.controls.password.value &&
                    !changePasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    changePasswordForm.controls.password.errors?.MinDigitsRequired
                "
            >
                {{ 'change-password.digits-required-password' | translate }}
            </div>
            <div
                class="error"
                *ngIf="
                    changePasswordForm.controls.password.value &&
                    !changePasswordForm.controls.password.errors?.lowerAndUpperRequired &&
                    !changePasswordForm.controls.password.errors?.MinDigitsRequired &&
                    changePasswordForm.controls.password.errors?.SpecialCharsRequired
                "
            >
                {{ 'change-password.special-chars-required-password' | translate }}
            </div>
        </div>

        <div>
            <label class="input-label">
                {{ 'change-password.repeat-password' | translate }}
                <input type="password" formControlName="passwordEcho" name="passwordEcho" id="passwordEcho" class="input-field" />
            </label>
            <div
                class="error"
                *ngIf="
                    !changePasswordForm.controls.passwordEcho.valid &&
                    changePasswordForm.controls.passwordEcho.errors?.required &&
                    submitted
                "
            >
                {{ 'change-password.required-password-repeat' | translate }}
            </div>
            <div
                class="error"
                *ngIf="changePasswordForm.controls.passwordEcho.value && changePasswordForm.controls.passwordEcho.errors?.matchPassword"
            >
                {{ 'change-password.not-match-password' | translate }}
            </div>
        </div>

        <button class="button blue mt-48" type="submit" (click)="changePassword(changePasswordForm, changePasswordForm.valid)">
            {{ 'change-password.change' | translate }}
        </button>
    </form>
</div>

<div class="column-container">
    <div class="container">
        <img src="../../../assets/img/Email_Unconfirmed.svg" alt="Email Unconfirmed" class="image" />
        <ng-container *ngIf="step === 1">
            <div class="title">
                {{ 'verification.phone.title' | translate }}
            </div>

            <div class="content" *ngIf="!smsSent && isVerificationSMSNotSent">
                {{ 'verification.phone.sending' | translate }}<br />
                {{ 'verification.wait' | translate }}
            </div>

            <div class="content" *ngIf="!smsSent || !isVerificationSMSNotSent">
                {{ 'verification.phone.subtitle' | translate : { userPhoneNumber } }}
                <mat-form-field color="accent" appearance="outline" class="form-field">
                    <mat-label> SMS Code</mat-label>
                    <input matInput type="text" [(ngModel)]="smsCode" maxlength="6" />
                </mat-form-field>
            </div>

            <div class="buttons">
                <button mat-flat-button color="primary" [disabled]="smsCode?.length !== 6" (click)="verifySMSCode(smsCode)">
                    {{ 'verification.verify-code' | translate }}
                </button>
                <button mat-stroked-button color="primary" (click)="isVerificationSMSNotSent = true; initiateAccountVerificationSMS()">
                    {{ 'verification.resend-code' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="step === 2">
            <div class="title">{{ 'verification.email.title' | translate }}</div>

            <div class="content" *ngIf="isVerificationEmailNotSent && !userEmail">
                {{ 'verification.email.sending' | translate }}<br />
                {{ 'verification.wait' | translate }}
            </div>

            <div class="content" *ngIf="isVerificationEmailNotSent && userEmail">
                {{ 'verification.email.sending-email' | translate : { userEmail } }}<br />
                {{ 'verification.wait' | translate }}
            </div>
            <div class="content subtitle" *ngIf="!isVerificationEmailNotSent">
                <p>{{ 'verification.email.subtitle.0' | translate : { userEmail } }}</p>
                <p>{{ 'verification.email.subtitle.1' | translate }}</p>
                <p>{{ 'verification.email.subtitle.2' | translate }}</p>
            </div>

            <div class="buttons">
                <!--                <button mat-flat-button color="accent" [queryParams]="{ requestId: requestId }" [routerLink]="['/login-credentials']">-->
                <!--                    {{ 'message.already-sent-to-login' | translate }}-->
                <!--                </button>-->
                <button mat-stroked-button color="primary" (click)="isVerificationEmailNotSent = true; initiateAccountVerificationEmail()">
                    {{ 'message.already-sent-resend' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</div>

<div class="column-container">
    <div class="container">
        <img src="../../../assets/img/House_Magic.svg" alt="Magic_House" class="image" />
        <div class="title">Ein letzter Schritt zum Traumhaus</div>
        <div class="content">
            <div class="info-text">
                Bevor ihr im nächsten Schritt weitere Infos zu deinen Matches erhaltet (z.B. Grundrisse, Preisindikation) und diese
                vergleichen könnt, benötigen wir eure Kontaktdaten. Diese sind unerlässlich, damit euch die Bauunternehmen hinter euren
                Matches kontaktieren können und ihr gemeinsam in die tiefere Planung eures Traumhauses starten könnt.
            </div>

            <div class="buttons">
                <button [queryParams]="{ requestId: requestId }" [routerLink]="['/register']" class="button" mat-flat-button color="accent">
                    Per E-Mail registrieren
                </button>
            </div>

            <div class="acc-exist-container">
                <div class="account-exist">Ihr habt schon einen Account?</div>
                <span>
                    <a [queryParams]="{ requestId: requestId }" [routerLink]="['/login-credentials']"> Zum&nbsp;Login</a>
                </span>
            </div>

            <div class="legal-text">
                Mit der Erstellung eines Accounts stimme ich den
                <a href="https://www.euerzuhause.de/nutzungsbedingungen" target="_blank">{{ 'register.terms-of-service' | translate }}</a>
                zu. Die
                <a href="https://www.euerzuhause.de/datenschutzerklaerung" target="_blank">{{ 'register.privacy-policy' | translate }}</a>
                einschließlich der Möglichkeiten zum Widerruf der Verarbeitung meiner Daten habe ich zur Kenntnis genommen.
            </div>
            <!--div class="buttons-label">Weiter mit...</div-->
        </div>
        <!--div class="buttons social"-->
        <!--button (click)="socialLogin('apple')" class="button" mat-flat-button>Apple</button>-->
        <!--button (click)="socialLogin('google')" class="button" mat-flat-button>Google</button>-->
        <!--button (click)="socialLogin('facebook')" class="button" mat-flat-button>Facebook</button-->
        <!--/div-->
        <!--div class="buttons-separator">oder</div-->
    </div>
</div>

<div class="container">
    <div class="title">
        {{'mfa.title' | translate}}
    </div>
    <form class="form">
        <div>
            <label class="input-label">
                {{'mfa.verification-code' | translate}}
                <input type="password" name="code" id="code" class="input-field">
            </label>
        </div>
        <button class="button blue mt-48" [disabled] type="submit">
            {{'mfa.submit' | translate}}
        </button>
        <button class="button mt-16" [routerLink]="['/login']"
                [queryParams]="{requestId: requestId}">
            {{'mfa.back' | translate}}
        </button>
    </form>
</div>

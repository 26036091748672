import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemMsgService } from 'src/app/services/system-msg.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    requestId = '';
    termsOfServiceLink = environment.termsOfServicePage;
    privacyPolicyLink = environment.dataPrivacyPage;

    constructor(private route: ActivatedRoute, private systemMsgService: SystemMsgService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.requestId = params.requestId;
        });
    }
}

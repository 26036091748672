import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CommunicationService {
    events: Observable<{ [key: string]: any }>;
    eventsSubject: any;
    listeners: { [key: string]: any };

    constructor() {
        this.listeners = {};
        this.eventsSubject = new BehaviorSubject<any>({});
        this.events = this.eventsSubject.asObservable();
        this.events.subscribe((params: { name; args }) => {
            if (this.listeners[params.name]) {
                for (const listener of this.listeners[params.name]) {
                    listener(...params.args);
                }
            }
        });
    }

    on(name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }
        this.listeners[name].push(listener);
    }

    broadcast(name, ...args) {
        this.eventsSubject.next({
            name,
            args,
        });
    }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export class InputFieldValidator {
    static validateText(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const text = control.value;
            if (!text.match('^[ +a-zA-Z0-9_.@-äüöÄÜÖß]+$')) {
                return { invalidText: true };
            } else {
                return null;
            }
        };
    }

    static validateNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const text = control.value;
            if (!text.match('^[ +0-9]+$')) {
                return { invalidNumber: true };
            } else {
                return null;
            }
        };
    }

    static validateURL(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (
                control.value &&
                !control.value.toString().trim().startsWith('http://') &&
                !control.value.toString().trim().startsWith('https://')
            ) {
                return { invalidURL: true };
            }
            return null;
        };
    }

    static validateCheckBox(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const checkBox: any[] = control.value;
            let checkBoxVal: any;
            checkBox.forEach((val) => {
                checkBoxVal = val;
            });
            if (!checkBoxVal) {
                return { required: true };
            } else {
                return null;
            }
        };
    }
}

<div class="container">
    <div class="title">
        {{ 'forget-success.title' | translate : { email } }}
    </div>
    <div class="subtitle">
        {{ 'forget-success.subtitle' | translate }}
    </div>
    <button
        mat-button
        mat-flat-button
        color="primary"
        class="button blue mt-48"
        type="button"
        [routerLink]="['/login']"
        [queryParams]="{ requestId: requestId }"
    >
        {{ 'forget-success.login' | translate }}
    </button>
</div>

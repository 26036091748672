<div class="columns">
    <div class="hero-images">
        <img src="../../../assets/img/login-house1.webp" />
        <img src="../../../assets/img/login-house4.webp" />
    </div>
    <div class="column-container">
        <div class="container">
            <div>
                <div class="title">{{ 'login.title' | translate }}</div>
                <div class="subtitle">
                    {{ 'login.subtitle.text' | translate }}
                    <a a [routerLink]="['/register']" [queryParams]="{ requestId: requestId }">{{ 'login.subtitle.link' | translate }}</a>
                </div>

                <div class="buttons social">
                    <button (click)="socialLogin('google')" class="button" mat-flat-button>Anmelden mit Google</button>
                    <button (click)="socialLogin('apple')" class="button" mat-flat-button>Anmelden mit Apple</button>
                </div>

                <div class="divider">
                    <span>oder</span>
                </div>

                <form [formGroup]="loginFormGroup" class="form">
                    <mat-form-field color="accent" appearance="outline" class="form-field">
                        <mat-label>{{ 'login.email' | translate }}</mat-label>
                        <input type="email" matInput formControlName="email" name="email" id="email" autocomplete="username" />
                        <mat-error *ngIf="loginFormGroup.controls.email.value && !loginFormGroup.controls.email.valid">
                            {{ 'login.invalid-email' | translate }}
                        </mat-error>
                        <mat-error *ngIf="!loginFormGroup.controls.email.value && !loginFormGroup.controls.email.valid && submitted">
                            {{ 'login.invalid-email' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field color="accent" appearance="outline" class="form-field">
                        <mat-label>{{ 'login.password' | translate }}</mat-label>
                        <input type="password" matInput formControlName="password" name="password" id="password" />
                        <mat-error *ngIf="!loginFormGroup.controls.password.valid && submitted">
                            {{ 'login.invalid-password' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <a [routerLink]="['/reset', requestId]" color="accent" class="link">
                        {{ 'login.forgot-password' | translate }}
                    </a>

                    <button mat-button mat-flat-button color="primary" type="submit" (click)="login(loginFormGroup)">
                        {{ 'login.login' | translate }}
                    </button>

                    <mat-error class="error center mt-16" *ngIf="isLoginError">{{ errorMessage | translate }}</mat-error>
                </form>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>

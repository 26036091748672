import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CidaasService, LoaderService, TranslationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordPolicy } from '../../models';
import { PasswordPolicyValidation, PasswordValidation, StorageHelper } from '../../config';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    passwordPolicy: PasswordPolicy;
    changePasswordForm: UntypedFormGroup;
    minlength = 0;
    maxlength = 100;
    identityId: string;
    loginSettingsId: string;
    requestId: string;
    digitsRequiredError: string;
    token: string;
    isChangePasswordError = false;
    changePasswordError: string;
    submitted = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changePasswordService: CidaasService,
        public loader: LoaderService,
        private translate: TranslationService
    ) {
        window.document.title = 'Passwort ändern';
        this.activatedRoute.params.subscribe((params) => {
            this.identityId = params['identityId'];
        });
        if (!this.identityId) {
            this.identityId = 'newUser';
            this.loginSettingsId = this.activatedRoute.snapshot.queryParams.lsid;
            this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        } else {
            this.getRequestId();
        }
    }

    ngOnInit() {
        this.createNewchangePasswordForm();
        this.getPasswordPolicy();
    }

    // Get Request Id (If Request Id is not came in URL)
    getRequestId() {
        this.changePasswordService
            .getRequestId()
            .then((response: string) => {
                this.requestId = response;
            })
            .catch(() => {});
    }

    // create new reset password form
    createNewchangePasswordForm() {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', [Validators.required]],
            password: ['', [Validators.required]],
            passwordEcho: ['', [Validators.required]],
        });
        this.changePasswordForm.setValidators(PasswordValidation.matchPassword);
    }

    // Get Password policy
    getPasswordPolicy() {
        this.loader.showLoader();
        this.changePasswordService
            .getPasswordPolicy(this.requestId)
            .then((response: any) => {
                this.passwordPolicy = response;
                if (this.passwordPolicy.minimumLength > 0) {
                    this.minlength = this.passwordPolicy.minimumLength;
                }
                if (this.passwordPolicy.maximumLength > 0) {
                    this.maxlength = this.passwordPolicy.maximumLength;
                }
                this.loader.hideLoader();
                this.setPasswordPolicyValidators();
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    // Set Password policy validators to Control
    setPasswordPolicyValidators() {
        if (this.passwordPolicy.lowerAndUpperCase) {
            if (this.minlength && this.maxlength > 0) {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                        ]);
                }
            } else {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.changePasswordForm.get('password').setValidators(PasswordPolicyValidation.requireLowerandUpper());
                }
            }
        } else {
            if (this.minlength && this.maxlength > 0) {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength)]);
                }
            } else {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators(PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits));
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.changePasswordForm
                        .get('password')
                        .setValidators(PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars));
                } else {
                }
            }
        }
    }

    changePassword(formValue: any, valid: boolean) {
        const formValues = formValue.value;
        this.submitted = true;
        const user: any = {};
        if (this.identityId !== 'newUser') {
            user.oldPassword = formValues.oldPassword;
            user.newPassword = formValues.password;
            user.confirmPassword = formValues.passwordEcho;
            user.identityId = this.identityId;
            this.loader.showLoader();
            this.changePasswordService
                .changePassword(user, this.token)
                .then((response: any) => {
                    this.submitted = false;
                    if (response.status === 400) {
                        this.isChangePasswordError = true;
                        this.changePasswordError = this.translate.getTranslatedMessage('change-password.incorrect-old-password');
                        this.loader.hideLoader();
                    } else {
                        StorageHelper.deleteAll();
                        this.loader.hideLoader();
                        this.changePasswordService.logoutUser(this.token);
                        this.router.navigate(['/reset_success']);
                    }
                })
                .catch(() => {
                    this.submitted = false;
                    this.loader.hideLoader();
                    this.isChangePasswordError = true;
                    this.changePasswordError = this.translate.getTranslatedMessage('change-password.error');
                });
        } else {
            if (valid) {
                this.submitted = false;
                user.oldPassword = formValues.oldPassword;
                user.newPassword = formValues.password;
                user.confirmPassword = formValues.passwordEcho;
                user.loginSettingsId = this.loginSettingsId;
                this.changePasswordService.firstTimeChangePassword(user);
            } else {
            }
        }
    }
}

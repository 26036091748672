import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemMsgService } from 'src/app/services/system-msg.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    requestId = '';

    constructor(private route: ActivatedRoute, private systemMsgService: SystemMsgService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.requestId = params.requestId;
        });
    }

    back() {
        this.systemMsgService.confirm('generic.leave-confirmation').subscribe(() => (window.location.href = environment.landingPage));
    }
}

import { Component, OnInit } from '@angular/core';
import { deleteCookie, getCookie } from '../../common/cookies.utils';
import { CidaasService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageHelper } from 'src/app/config';
import { registerGAEvent } from 'src/app/services/common.service';
import { SystemMsgService } from '../../services/system-msg.service';
import { RegistrationFlow } from '../register/register.component';
import { MixpanelService } from '../../services/mixpanel.service';

enum VerificationStep {
    SMS = 1,
    EMAIL = 2,
}

const COOKIE_DOMAINS = ['.euerzuhause.de', '.euerzuhause.io', 'localhost'];
const JUST_PHONE_VERIFICATION_COOKIE_NAME = 'ezh-phone-verification';

const EMAIL_NOT_VERIFIED_ERROR = 'email_not_verified';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
    static lastUsedRequestIdStorageKey = 'lastUsedRequestId';

    requestId: string;
    trackId: string;
    sub: string;
    isVerificationEmailNotSent: boolean;
    isVerificationSMSNotSent: boolean;
    userEmail: string;
    smsSent = false;

    step: VerificationStep = VerificationStep.EMAIL;
    emailTriggeredPrefix = 'email_triggered_';
    smsTriggeredPrefix = 'sms_triggered_';
    justPhoneVerificationTriggeredPrefix = 'just_phone_verification_triggered_';
    smsCode: string;
    userPhoneNumber: string;

    constructor(
        private cidaasService: CidaasService,
        private activatedRoute: ActivatedRoute,
        private systemMsgService: SystemMsgService,
        private router: Router,
        private mixpanel: MixpanelService
    ) {
        if (this.activatedRoute.snapshot.queryParams.sub) {
            StorageHelper.saveData('sub', this.activatedRoute.snapshot.queryParams.sub);
        }
        if (this.activatedRoute.snapshot.queryParams.trackId) {
            StorageHelper.saveData('trackId', this.activatedRoute.snapshot.queryParams.trackId);
        }
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        this.sub = StorageHelper.getData('sub');
        this.trackId = StorageHelper.getData('trackId');

        const queryParams = this.activatedRoute.snapshot.queryParams;

        if (queryParams['error'] === EMAIL_NOT_VERIFIED_ERROR) {
            this.initiateAccountVerificationEmail();
        }
    }

    ngOnInit() {
        const queryParams = this.activatedRoute.snapshot.queryParams;

        if (queryParams[`suggested_action`] && queryParams[`suggested_action`] === 'LOGIN') {
            if (this.trackId) {
                StorageHelper.saveData('trackId', '');
                this.cidaasService.loginAfterRegister(this.trackId);
            }

            this.router.navigate(['login-credentials'], {
                queryParams: { requestId: this.requestId },
            });
            return;
        }

        if (queryParams.verify) {
            if (queryParams.verify === RegistrationFlow.EMAIL_AND_PHONE.toLowerCase()) {
                this.step = VerificationStep.SMS;
            } else if (queryParams.verify === RegistrationFlow.EMAIL.toLowerCase()) {
                this.step = VerificationStep.EMAIL;
            }

            switch (this.step) {
                case VerificationStep.SMS:
                    this.initiateAccountVerificationSMS();
                    break;
                case VerificationStep.EMAIL:
                    this.initiateAccountVerificationEmail();
                    break;
            }
        }

        if (queryParams[`type`] && queryParams[`type`] === 'sms') {
            this.step = VerificationStep.SMS;
            StorageHelper.saveData(this.smsTriggeredPrefix + this.requestId, queryParams.accvid);
            this.accountVerificationSMSSent(queryParams);

            const justPhoneVerificationRedirectUrlFromCookie = getCookie(JUST_PHONE_VERIFICATION_COOKIE_NAME);
            if (justPhoneVerificationRedirectUrlFromCookie) {
                StorageHelper.saveData(
                    this.justPhoneVerificationTriggeredPrefix + this.requestId,
                    justPhoneVerificationRedirectUrlFromCookie
                );
                COOKIE_DOMAINS.forEach((domain) => deleteCookie({ name: JUST_PHONE_VERIFICATION_COOKIE_NAME, domain }));
            }
        }

        if (queryParams[`type`] && queryParams[`type`] === 'email') {
            this.step = VerificationStep.EMAIL;
            StorageHelper.saveData(this.emailTriggeredPrefix + this.requestId, new Date().getTime().toString());
            this.accountVerificationEmailSent(queryParams);
            registerGAEvent('event', 'email_verification_shown');
            registerGAEvent('page_view', 'email_verification_shown', { pageLocation: `/${this.activatedRoute.snapshot?.url[0]?.path}` });
        }

        this.isVerificationEmailNotSent = !StorageHelper.getData(this.emailTriggeredPrefix + this.requestId);
        this.isVerificationSMSNotSent = !StorageHelper.getData(this.smsTriggeredPrefix + this.requestId);

        // if (this.shouldSendSMS) {
        //     this.initiateAccountVerificationSMS();
        // }
    }

    initiateAccountVerificationEmail() {
        this.userEmail = '';
        this.cidaasService.initiateAccountVerification({ requestId: this.requestId, sub: this.sub }, 'email', 'LINK');
    }

    accountVerificationEmailSent(data) {
        StorageHelper.saveData(VerificationComponent.lastUsedRequestIdStorageKey, this.requestId);
        this.userEmail = data.email;
        setTimeout(() => {
            registerGAEvent('event', 'email_verification_email_sent', { requestId: this.requestId });
        }, 1000);
    }

    initiateAccountVerificationSMS() {
        this.smsSent = false;
        this.mixpanel.timeEvent({ event: 'verify_phone', uniqueId: this.sub });
        this.cidaasService.initiateAccountVerification({ requestId: this.requestId, sub: this.sub }, 'sms', 'CODE');
    }

    accountVerificationSMSSent(data) {
        this.smsSent = true;
        this.userPhoneNumber = data.mobile;
    }

    verifySMSCode(code: string) {
        const accvid = StorageHelper.getData(this.smsTriggeredPrefix + this.requestId);
        this.cidaasService
            .verifyAccount({ accvid, code })
            .then((response) => {
                const justPhoneVerificationRedirectUrl = StorageHelper.getData(this.justPhoneVerificationTriggeredPrefix + this.requestId);
                if (justPhoneVerificationRedirectUrl) {
                    StorageHelper.deleteData(this.justPhoneVerificationTriggeredPrefix + this.requestId);
                    window.location.href = justPhoneVerificationRedirectUrl;
                } else {
                    this.accountVerificationSMSVerified(response);
                }
            })
            .catch((ex) => {
                console.log(ex);
                this.systemMsgService.error(ex);
            });
    }

    accountVerificationSMSVerified(response: any) {
        console.log(response);
        if (response?.error) {
            console.log(response?.error);
            this.systemMsgService.error(response?.error.error);
        } else {
            this.step = 2;
            this.initiateAccountVerificationEmail();
        }
    }
}

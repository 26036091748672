import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageHelper } from 'src/app/config';
import { registerGAEvent } from 'src/app/services/common.service';
import { CidaasService } from '../../services';

@Component({
    selector: 'app-register-success',
    templateUrl: './register-success.component.html',
    styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent implements OnInit {
    requestId: string;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private cidaasService: CidaasService) {
        window.document.title = 'Registrierung abgeschlossen';
    }

    ngOnInit() {
        registerGAEvent('page_view', 'registration_success_shown', { pageLocation: `/${this.activatedRoute.snapshot?.url[0]?.path}` });
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        const trackId = this.activatedRoute.snapshot.queryParams.trackId;
        // if (StorageHelper.getData('mail_triggered')) {
        //     StorageHelper.deleteData('mail_triggered');
        // }
        if (trackId) {
            // Check if was already used, as can be used only once by Cidaas
            if (StorageHelper.getData('track_id_' + trackId)) {
                this.router.navigate(['login-credentials'], { queryParams: { requestId: this.requestId } });
            } else {
                StorageHelper.saveData('track_id_' + trackId, 'true');
                this.cidaasService.loginAfterRegister(trackId);
            }
        } else {
            setTimeout(() => {
                this.router.navigate(['login-credentials'], { queryParams: { requestId: this.requestId } });
            }, 5000);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { CidaasService, LoaderService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

declare let fingerPrint2: any;

@Component({
    selector: 'app-mfa-verify',
    templateUrl: './mfa-verify.component.html',
    styleUrls: ['./mfa-verify.component.scss'],
})
export class MfaVerifyComponent implements OnInit {
    public mfaVerifyForm: UntypedFormGroup;
    user: any = {};
    requestId: string;
    statusId: string;
    sub: string;
    type: string;
    fingerprint: string;
    trackId: string;
    trackingCode: string;
    isVerifyError = false;
    submitted = false;

    constructor(
        private userstoreService: CidaasService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private loader: LoaderService
    ) {
        this.route.params.subscribe((params) => {
            this.statusId = params['statusId'];
            this.sub = params['sub'];
            this.type = params['type'];
            this.trackId = params['trackId'];
            this.requestId = params['requestId'];
        });
    }

    ngOnInit() {
        this.createNewMFAVerifyForm();
        try {
            new fingerPrint2().get((result) => {
                this.fingerprint = result;
            });
        } catch (ex) {
            this.fingerprint = navigator.userAgent;
        }
    }

    // create new Mfa Verify Form
    createNewMFAVerifyForm() {
        this.mfaVerifyForm = this.formBuilder.group({
            code: ['', [Validators.required]],
        });
    }

    onVerify(formValue: any, valid: boolean) {
        console.log(formValue.code);
        this.submitted = true;
        if (valid) {
            this.submitted = false;
            if (this.type === 'email') {
                this.authenticateEmail(formValue.code);
            } else {
                this.authenticateTOTP(formValue.code);
            }
        }
    }

    authenticateTOTP(code) {
        this.loader.showLoader();
        const user: any = {};
        user.pass_code = code;
        user.exchange_id = this.statusId;
        user.sub = this.sub;
        user.type = 'TOTP';
        this.userstoreService
            .authenticateTOTP(user)
            .then((response: any) => {
                if (!response) {
                    this.isVerifyError = true;
                    this.loader.hideLoader();
                } else {
                    this.trackingCode = response.status_id;
                    this.loader.hideLoader();
                    this.mfaContinue();
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    authenticateEmail(code) {
        this.loader.showLoader();
        const user: any = {};
        user.pass_code = code;
        user.exchange_id = this.statusId;
        user.sub = this.sub;
        user.type = 'EMAIL';
        this.userstoreService
            .authenticateEmail(user)
            .then((response: any) => {
                if (!response) {
                    this.loader.hideLoader();
                    this.isVerifyError = true;
                } else {
                    this.trackingCode = response.status_id;
                    this.loader.hideLoader();
                    this.mfaContinue();
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    mfaContinue() {
        const user: any = {};
        user.trackId = this.trackId;
        user.status_id = this.trackingCode;
        user.requestId = this.trackId;
        user.verificationType = this.type.toUpperCase();
        user.sub = this.sub;
        this.loader.showLoader();
        this.userstoreService.mfaContinue(user);
    }
}

import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
    static matchPassword(ac: AbstractControl) {
        const password = ac.get('password'); // to get value in input tag
        const confirmPassword = ac.get('password_echo'); // to get value in input tag
        if (password?.value !== confirmPassword?.value) {
            confirmPassword?.setErrors({ matchPassword: true });
        } else {
            delete confirmPassword.errors?.matchPassword;
            confirmPassword?.setErrors(Object.keys(confirmPassword.errors || {}).length ? confirmPassword.errors : null);
            return null;
        }
    }

    static passwordStrengthValidator(
        value: string,
        isRequired = true,
        minChars = 10,
        minDigits = 1,
        minSpecial = 0,
        maxChars = 20
    ): {
        error: string;
        value: string | number;
    }[] {
        if (!value) {
            return isRequired ? [{ error: 'required', value: 0 }] : [];
        }

        const errors: {
            error: string;
            value: string | number;
        }[] = [];

        // const moreThanCharacters = /^\S{10,}$/g;
        const moreThanCharacters = new RegExp(`^\\S{${minChars},}$`, 'g');
        if (!moreThanCharacters.test(value)) {
            errors.push({ error: 'min-chars', value: minChars });
        }

        const lessThanCharacters = new RegExp(`^\\S{0,${maxChars}}$`, 'g');
        if (!lessThanCharacters.test(value)) {
            errors.push({ error: 'max-chars', value: maxChars });
        }

        const upperCaseCharacters = /[A-Z]+/g;
        if (!upperCaseCharacters.test(value)) {
            errors.push({ error: 'upper-case-characters', value: '' });
        }

        const lowerCaseCharacters = /[a-z]+/g;
        if (!lowerCaseCharacters.test(value)) {
            errors.push({ error: 'lower-case-characters', value: '' });
        }

        // At least n digits
        const numberCharacters = new RegExp(`(?=(.*\\d){${minDigits},})`, 'g');
        if (!numberCharacters.test(value)) {
            errors.push({ error: 'number-characters', value: minDigits });
        }

        // At least n special chars
        const specialCharacters = new RegExp(`(?=(.*[!@#$%^&*()_+\\-=\\[\\]{};':"\\\\|,.<>\\/?]){${minSpecial},})`, 'g');
        if (!specialCharacters.test(value)) {
            errors.push({ error: 'special-characters', value: minSpecial });
        }

        return errors;
    }
}

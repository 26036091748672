import { Component, OnInit } from '@angular/core';
import { CidaasService } from 'src/app/services/cidaas.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit {
    requestId: string;
    sub: string;
    clientId: string;
    consentName: string;
    consentVersion: number;
    consentId: string;
    consentVersionId: string;
    trackId: string;
    consentResponse: any;
    consentScopes: any[] = [];
    isScopePresent = false;

    constructor(private cidaasService: CidaasService, private activatedRoute: ActivatedRoute, public loader: LoaderService) {
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        this.trackId = this.activatedRoute.snapshot.queryParams.trackId || this.activatedRoute.snapshot.queryParams.track_id;
        this.sub = this.activatedRoute.snapshot.queryParams.sub;
        this.clientId = this.activatedRoute.snapshot.queryParams.client_id;
        this.consentName = this.activatedRoute.snapshot.queryParams.consentName;
        this.consentVersion = this.activatedRoute.snapshot.queryParams.consentVersion;
        this.consentId = this.activatedRoute.snapshot.queryParams.consentId || this.activatedRoute.snapshot.queryParams.consent_id;
        this.consentVersionId =
            this.activatedRoute.snapshot.queryParams.consentVersionId || this.activatedRoute.snapshot.queryParams.consent_version_id;
    }

    ngOnInit() {
        this.getConsentDetails();
    }

    getConsentDetails() {
        const options: any = {
            sub: this.sub,
            clientId: this.clientId,
            consentId: this.consentId,
            consentVersionId: this.consentVersionId,
            // Because Cidaas variable naming style is not something we can count on...
            client_id: this.clientId,
            consent_id: this.consentId,
            consent_version_id: this.consentVersionId,
        };
        this.loader.showLoader();
        this.cidaasService
            .getConsentDetails(options)
            .then((response) => {
                this.consentResponse = response;
                if (!this.consentName) {
                    this.consentName = this.consentResponse?.consentName;
                }
                if (this.consentResponse?.scopes && this.consentResponse.scopes.length > 0) {
                    this.consentScopes = this.consentResponse.scopes;
                    this.isScopePresent = true;
                }
                this.loader.hideLoader();
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    acceptConsent() {
        const options: any = {
            clientId: this.clientId,
            consentId: this.consentId,
            consentVersionId: this.consentVersionId,
            // Because Cidaas variable naming style is not something we can count on...
            client_id: this.clientId,
            consent_id: this.consentId,
            consent_version_id: this.consentVersionId,
            sub: this.sub,
        };
        this.loader.showLoader();
        this.cidaasService
            .acceptConsent(options)
            .then(() => {
                this.loader.hideLoader();
                this.consentContinue();
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    consentContinue() {
        const options: any = {
            clientId: this.clientId,
            trackId: this.trackId,
            version: this.consentVersionId,
            sub: this.sub,
            name: this.consentName,
            client_id: this.clientId,
            consent_id: this.consentId,
            consent_version_id: this.consentVersionId,
            track_id: this.trackId,
        };
        this.cidaasService.consentContinue(options);
    }
}

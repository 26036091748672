<div class="container">
    <img src="../../../assets/img/checkmark.svg" alt="Email Confirmed" class="image" />

    <div class="content">
        {{ 'reset-success.subtitle' | translate }}
    </div>
    <button
        mat-button
        mat-flat-button
        color="primary"
        class="button blue mt-48"
        type="button"
        [routerLink]="['/login-credentials']"
        [queryParams]="{ requestId: requestId }"
    >
        {{ 'reset-success.login' | translate }}
    </button>
</div>

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SystemMsgService {
    private subject = new Subject<any>();

    constructor() {}

    /**
     * Shows dialog with multiple buttons
     *
     * @param title: title of
     * @param message: content to display
     * @param buttons: array of objects: {'title':'title', 'callback':callbackFunction}
     * @param cssClass: css class to add
     * @param param: key value object to replace template in translation
     */
    dialog(
        title: string,
        message: string,
        buttons: { value?: any; title: string; cssClass?: string }[],
        cssClass: string = 'message',
        param?: { [key: string]: unknown }
    ): Observable<any> {
        const buttonSubject = new Subject<any>();
        this.subject.next({
            type: 'dialog',
            title,
            message,
            buttons,
            cssClass,
            param,
            buttonSubject,
        });
        return buttonSubject.asObservable();
    }

    alert(message: string, cssClass: string = 'message', param?: { [key: string]: unknown }): Observable<any> {
        return this.dialog('generic.alert', message, [{ title: 'generic.close', value: {} }], cssClass, param);
    }

    error(message: string, param?: { [key: string]: unknown }): Observable<any> {
        return this.dialog('generic.error', message, [{ title: 'generic.close', value: {} }], 'error', param);
    }

    warning(message: string, param?: { [key: string]: unknown }): Observable<any> {
        return this.dialog('generic.warning', message, [{ title: 'generic.close', value: {} }], 'warning', param);
    }

    confirm(
        message: string,
        param?: { [key: string]: unknown },
        title: string = 'generic.confirm',
        confirm: string = 'generic.yes',
        cancel: string = 'generic.cancel'
    ): Observable<any> {
        return this.dialog(
            title,
            message,
            [
                { title: cancel, cssClass: 'secondary' },
                { title: confirm, value: {} },
            ],
            'message',
            param
        );
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
